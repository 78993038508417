import HomeGal1 from "../Components/HomeGalery1";
import Navbar from "../Components/Navigation";
import Footer from "../Components/GardenResFooter"


export default function Home() {
    return (
        <section className="sm:overflow-hidden md:overflow-hidden">
            <Navbar />
            <section className={`lg:w-full lg:-mt-12 lg:h-screen lg:bg-cover bg-center bg-no-repeat Homebgpic bg-gray-400 bg-blend-multiply sm:-mb-[40px] sm:-mt-[70px] sm:h-[700px]`}>
                <div className="mx-auto max-w-screen-xl text-center py-24 sm:px-2 lg:py-56">
                    <div className="border-[5px] pb-[170px]">
                        <h1 className="tracking-widest font-seriff pt-40 mb-4 text-7xl font-light text-white md:text-7xl sm:text-2xl lg:text-7xl">Garden Residences</h1>
                        <p className="tracking-widest mb-8 text-lg -mr-[220px] -ml-[220px] font-seriff font-light text-white lg:text-3xl sm:px-16 lg:px-48">A PLACE YOU CALL HOME</p>

                        <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:px-28">
                            <a href="https://facebook.com/profile.php?id=100077859550266" target="_blank" rel="noopener noreferrer">
                                <button type="button" className="sm:-mx-[120px] lg:py-5 lg:px-8 lg:text-lg max-width-100% py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Reserve Now</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <HomeGal1 />
            <Footer />
        </section>
    )
}